export type WindowWithClerk = Window & {
  Clerk?: {
    session?: {
      getToken(): Promise<string | null>;
    };
  };
};

export const CLERK_STATUSES = [
  'verified',
  'failed',
  'expired',
  'client_mismatch',
] as const;

export type ClerkStatus = (typeof CLERK_STATUSES)[number];
