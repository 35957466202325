import { useAuth, useClerk, useSignIn, useUser } from '@clerk/nextjs';
import type { UserResource } from '@clerk/types';
import * as Sentry from '@sentry/nextjs';
import { useEffect } from 'react';

import type { ClerkStatus } from '@/types/clerk';
import { getBaseUrl } from '@/utils/Helpers';

interface UseClerkVerificationProps {
  clerkStatus: ClerkStatus | null;
  createdSessionId: string | null;
}

export const useClerkVerification = ({
  clerkStatus,
  createdSessionId,
}: UseClerkVerificationProps) => {
  const { handleEmailLinkVerification } = useClerk();
  const { setActive } = useSignIn();
  const { user } = useUser();
  const { orgId } = useAuth();

  useEffect(() => {
    const setActiveOrganization = async (
      sessionId: string,
      currentUser: UserResource,
    ) => {
      const { data: memberships } =
        await currentUser.getOrganizationMemberships();

      if (memberships.length > 0) {
        // We're naively setting the first org as the active org
        await setActive?.({
          session: sessionId,
          organization: memberships[0]?.organization.id,
        });
      }
    };

    const activateSession = async (
      sessionId: string,
      currentUser: UserResource,
    ) => {
      await setActive?.({ session: sessionId });

      // If there is no orgId in the session, set the active organization
      if (!orgId) {
        await setActiveOrganization(sessionId, currentUser);
      }
    };

    const handleVerification = async () => {
      await handleEmailLinkVerification({
        redirectUrl: `${getBaseUrl()}/sign-in/factor-one`,
        redirectUrlComplete: `${getBaseUrl()}/dashboard`,
      });

      const canActivateSession =
        clerkStatus === 'verified' && createdSessionId && setActive && user;

      if (canActivateSession) {
        await activateSession(createdSessionId, user);
      }
    };

    handleVerification().catch((err) => {
      Sentry.captureException(err, {
        extra: {
          userId: user?.id,
          clerkStatus,
          context: 'sign_in_verification',
        },
      });
    });
  }, [
    handleEmailLinkVerification,
    setActive,
    createdSessionId,
    clerkStatus,
    user,
    orgId,
  ]);
};
