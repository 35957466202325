'use client';

import { usePathname } from 'next/navigation';

import SignInEmailForm from './SignInEmailForm';
import SignInVerificationForm from './SignInVerificationForm';

interface SignInFormProps {
  invitationTicket?: string | null;
}

const SignInForm = ({ invitationTicket }: SignInFormProps) => {
  const pathname = usePathname();
  const isVerificationStep = pathname.includes('factor-one');

  return (
    <div>
      {!isVerificationStep ? (
        <SignInEmailForm invitationTicket={invitationTicket} />
      ) : (
        <SignInVerificationForm />
      )}
    </div>
  );
};

export default SignInForm;
